<script setup>

import {onMounted, ref} from "vue";

const currentCardTab = ref('coach');
const cardTransitioning = ref(false);
const currentTestimonial = ref(0)
const testimonialTransitioning = ref(false)
const transitionsActive = ref(true)

const props = defineProps({
    uuid: String,
});


const cardTabs = {
    coach: {
        title: 'Coach',
        slug: 'coach',
        headline: 'Everything you need as a coach',
        byline: 'Get flow in your coaching business. Packed with features to generate new revenue, acquire more customers, and save time.',
        image: 'https://myflow.fra1.digitaloceanspaces.com/prereg/marie.png',
        boxes: [
            'Coaching',
            'Scheduling',
            'Payments',
            'CRM',
            'Web Pages',
            'Lead Hunter AI',
            'Newsletter',
            'Quotes',
            'Agreements',
        ],
    },
    consultant: {
        title: 'Consultant',
        slug: 'consultant',
        headline: 'Everything you need as a consultant',
        byline: 'Get flow in your consulting business. Packed with features to generate new revenue, acquire more customers, and save time.',
        image: 'https://myflow.fra1.digitaloceanspaces.com/prereg/thibbe.png',
        boxes: [
            'Time Tracking',
            'Quotes',
            'Agreements',
            'Website',
            'Landing Pages',
            'Lead Hunter AI',
            'Payments',
            'CRM',
            'Newsletter',
        ],
    },
    speaker: {
        title: 'Speaker',
        slug: 'speaker',
        headline: 'Everything you need as a speaker',
        byline: 'Get flow in your speaker business. Packed with features to generate new revenue, acquire more customers, and save time.',
        image: 'https://myflow.fra1.digitaloceanspaces.com/prereg/charbel.png',
        boxes: [
            'Payments',
            'Website',
            'Landing Pages',
            'Agreements',
            'Quotes',
            'Courses',
            'Newsletter',
            'Lead Hunter AI',
            'Automations',
        ],
    },
    educator: {
        title: 'Creator',
        slug: 'educator',
        headline: 'Everything you need as an creator',
        byline: 'Get flow in your creator business. Packed with features to generate new revenue, acquire more customers, and save time.',
        image: 'https://myflow.fra1.digitaloceanspaces.com/prereg/jonathan.png',
        boxes: [
            'Courses',
            'Subscriptions',
            'Newsletters',
            'Payment',
            'Automations',
            'Funnels',
            'CRM',
            'Landing Pages',
            'Website',
        ],
    },
}

const testimonials = [
    {
        body: 'I love how myflow simplifies my everyday life as an entrepreneur. It\'s is almost too good to be true. myflow really helps me and my clients to streamline our business.',
        author: 'Mari Höglund, Coach & Growth Assistant',
    },
    {
        body: 'I was tired of patching together dozens of software applications. Now, a single platform manages my business, covering everything from CRM to marketing & sales.',
        author: 'Thibaud Hartwig, Management Consultant',
    },
    {
        body: 'I love how easy it is for me to share my passion and knowledge to thousands of people all over the world. myflow really helped me to kick-start my digital journey.',
        author: 'Charbel Gabro, Keynote Speaker',
    },
    {
        body: 'I train coaches & speakers daily. I can truly see the difference myflow makes for them - both new and seasoned professionals progress towards their goals.',
        author: 'Jonathan Ljungqvist, Coach Educator',
    },
]

const manuallySetCardTab = (tab) => {
    const currentTabs = {
        coach: {
            tab: 'consultant',
            testimonial: 0,
        },
        consultant: {
            tab: 'speaker',
            testimonial: 1,
        },
        speaker: {
            tab: 'educator',
            testimonial: 2,
        },
        educator: {
            tab: 'coach',
            testimonial: 3,
        },
    }

    transitionsActive.value = false
    currentCardTab.value = tab;
    currentTestimonial.value = currentTabs[tab].testimonial;
}

const setCardTab = (tab) => {
    cardTransitioning.value = true;
    setTimeout(function(){
        currentCardTab.value = tab;

        setTimeout(function(){
            cardTransitioning.value = false;
        }, 150)
    }, 300)
};

const setTestimonial = (index) => {
    testimonialTransitioning.value = true;
    setTimeout(function () {
        currentTestimonial.value = index;

        setTimeout(function () {
            testimonialTransitioning.value = false;
        }, 150)
    }, 300)
}

const setNextTab = () => {
    const nextTabs = {
        coach: {
            tab: 'consultant',
            testimonial: 1,
        },
        consultant: {
            tab: 'speaker',
            testimonial: 2,
        },
        speaker: {
            tab: 'educator',
            testimonial: 3,
        },
        educator: {
            tab: 'coach',
            testimonial: 0,
        },
    }

    setCardTab(nextTabs[currentCardTab.value].tab);
    setTestimonial(nextTabs[currentCardTab.value].testimonial);
}

onMounted(() => {
    setInterval(function() {
        if (transitionsActive.value === false) {
            return;
        }
        cardTransitioning.value = true;
        setTimeout(function () {
            setNextTab()
            setTimeout(function () {
                cardTransitioning.value = false;
            }, 150)
        }, 300)
    }, 8000)

    setInterval(function() {
        if (transitionsActive.value === false) {
            return;
        }
        testimonialTransitioning.value = true;
        setTimeout(function () {
            currentTestimonial.value++;

            if (currentTestimonial.value >= testimonials.length) {
                currentTestimonial.value = 0;
            }

            setTimeout(function () {
                testimonialTransitioning.value = false;
            }, 150)
        }, 300)
    }, 8000)
})

</script>
<template>
    <div class="bg-white">
        <div class="relative z-10 w-full py-4 text-sm text-center bg-myflowPurple-light text-myflowPurple">
            You have been invited to myflow
        </div>
        <div class="flex items-center min-h-screen py-20 -mt-8 onboarding-faded-bg">
            <div class="w-full max-w-5xl p-4 mx-auto">
                <div class="mb-8 text-center">
                    <img
                        src="https://myflow.fra1.digitaloceanspaces.com/prereg/logo-myflow.png"
                        class="h-auto mx-auto w-28"
                    />
                </div>

                <h1 class="mb-8 text-3xl font-semibold text-center md:text-5xl" style="line-height: 1.2em;">
                    <span class="text-myflowPurple">One</span> platform.<br>
                    Tons of tools for <span class="text-myflowPurple">success.</span>
                </h1>

                <div
                >
                    <video-player
                        class="max-w-xl mx-auto mb-8"
                        hls="https://myflow.fra1.digitaloceanspaces.com/mediabankentry/hls/5b64fdce-929f-4620-a12a-e4786ba2e9c4/5b64fdce-929f-4620-a12a-e4786ba2e9c4.m3u8"
                        poster="/hls-gif"
                        :autoplay="false"
                    ></video-player>
                </div>

                <div class="flex flex-col items-center">
                    <a :href="route('expert.register.shortcut', props.uuid) + '?utm_source=info_page'">
                        <button
                            class="px-10 py-3 mb-2 text-white rounded-lg bg-myflowPurple"
                        >
                            Jump in for free
                        </button>
                    </a>
                    <p class="text-xs">free forever</p>
                </div>
            </div>
        </div>
        <div class="min-h-screen -mt-20">
            <div class="w-full max-w-5xl p-4 mx-auto">
                <p class="mb-6 text-lg font-medium text-center">Perfectly made for you as a...</p>

                <div class="w-full max-w-2xl mx-auto mb-12 lg:max-w-3xl">
                    <div class="flex items-end justify-center pt-4 space-x-1 overflow-hidden md:space-x-4">
                        <button
                            class="rounded-t-2xl px-2 pb-2 text-sm font-medium pt-2.5 shadow-tab md:px-5 md:text-base"
                            :class="currentCardTab === 'coach' ? 'text-myflowPurple' : ''"
                            @click="manuallySetCardTab('coach')"
                        >
                            Coach
                        </button>
                        <button
                            class="rounded-t-2xl px-2 pb-2 text-sm font-medium pt-2.5 shadow-tab md:px-5 md:text-base"
                            :class="currentCardTab === 'consultant' ? 'text-myflowPurple' : ''"
                            @click="manuallySetCardTab('consultant')"
                        >
                            Consultant
                        </button>
                        <button
                            class="rounded-t-2xl px-2 pb-2 text-sm font-medium pt-2.5 shadow-tab md:px-5 md:text-base"
                            :class="currentCardTab === 'speaker' ? 'text-myflowPurple' : ''"
                            @click="manuallySetCardTab('speaker')"
                        >
                            Speaker
                        </button>
                        <button
                            class="rounded-t-2xl px-2 pb-2 text-sm font-medium pt-2.5 shadow-tab md:px-5 md:text-base"
                            :class="currentCardTab === 'educator' ? 'text-myflowPurple' : ''"
                            @click="manuallySetCardTab('educator')"
                        >
                            Creator
                        </button>
                    </div>
                    <div
                        class="p-2 rounded-4xl shadow-card"
                    >
                        <div
                            v-for="(tab, key) in cardTabs"
                        >
                            <div
                                v-if="currentCardTab === tab.slug"
                                class="grid-cols-5 gap-4 p-4 m-2 rounded-3xl card-inner md:grid md:p-6 lg:p-8"
                            >
                                <div
                                    class="col-span-3 text-white transition-all"
                                    :class="cardTransitioning ? 'opacity-0 duration-300' : 'opacity-100 duration-1000'"
                                >
                                    <h2 class="mb-2 text-3xl font-medium md:w-3/4">{{ tab.headline}}</h2>
                                    <p class="mb-12 text-base">{{ tab.byline }}</p>

                                    <div class="grid grid-cols-3 gap-2 mb-8">
                                        <div
                                            v-for="(item, i) in tab.boxes"
                                            :key="i"
                                            class="rounded-xl px-1 text-center text-xs font-semibold text-black py-2.5 bg-myflowPurple-light"
                                        >
                                            {{ item }}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="flex items-end col-span-2 transition-all"
                                    :class="cardTransitioning ? 'opacity-0 duration-300' : 'opacity-100 duration-1000'"
                                >
                                    <img
                                        :src="tab.image"
                                        class="-mb-4 origin-bottom transform scale-110 md:-mb-6 md:translate-x-10 md:scale-125 lg:-mb-8"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="w-full max-w-2xl mx-auto mb-12 lg:max-w-3xl">
                    <div class="p-2 rounded-4xl shadow-card">
                        <div
                            v-for="(testimonial, key) in testimonials"
                            class="overflow-hidden transition-all duration-500"
                        >
                            <div v-if="currentTestimonial === key">
                                <div
                                    class="flex flex-col-reverse p-4 m-2 space-x-4 bg-gray-100 rounded-3xl md:flex-row md:p-6 lg:p-8"
                                    :class="testimonialTransitioning ? 'opacity-0 duration-300' : 'opacity-100 duration-1000'"
                                >
                                    <div class="flex items-center justify-center mt-4 space-x-1 w-80 md:mt-0 md:justify-start">
                                        <img
                                            v-for="i in 5"
                                            src="https://myflow.fra1.digitaloceanspaces.com/prereg/Icon%20fa-solid-star.png"
                                            class="w-6 h-6"
                                        />
                                    </div>
                                    <div>
                                        <div class="md:w-3/4">
                                            <p class="mb-1">{{ testimonial.body }}</p>
                                            <div class="font-semibold">{{ testimonial.author }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pt-24 pb-64">
                    <div class="w-full max-w-5xl p-4 mx-auto">
                        <img src="https://myflow.fra1.digitaloceanspaces.com/prereg/all-icons.png" class="w-full mx-auto mb-4 max-w-48" />

                        <div class="mb-8 text-4xl font-semibold text-center">
                            <p>Packed with <span class="text-myflowPurple">features</span>.</p>
                            <p>Everything you need to <span class="text-myflowPurple">succeed</span>.</p>

                        </div>
                        <div class="flex flex-col items-center">
                            <a :href="route('expert.register.shortcut', props.uuid) + '?utm_source=info_page'">
                                <button
                                    class="px-10 py-3 mb-2 text-white rounded-lg bg-myflowPurple"
                                >
                                    Jump in for free
                                </button>
                            </a>
                            <p class="text-xs">free forever</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.shadow-tab {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}

.shadow-card {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
}

.card-inner {
    background-image: url('https://myflow.fra1.digitaloceanspaces.com/prereg/background.png');
    background-size: cover;
    background-position: bottom center;
}

.onboarding-faded-bg {
    background-image: url(https://myflow.fra1.digitaloceanspaces.com/prereg/faded-bg.png);
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
}
</style>
